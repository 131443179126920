import React from 'react';
import logo from './logo.svg';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h2>Sfida app</h2>
        <p>Are you ready to join the alpha test group?</p>
        <a
          className="App-link"
          href="https://play.google.com/apps/testing/app.lpap.sfida"
          target="_blank"
          rel="noopener noreferrer"
        >
          Signup and download the app
        </a>
      </header>
    </div>
  );
}

export default App;
